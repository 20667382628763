<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title
        >{{ data.name ? data.name : "Новый счет" }}
        <small v-if="id"> ({{ id }})</small>
      </template>
      <v-row>
        <v-col cols="12">
          <a-input-select-account
            v-model="data"
            :model="model"
            :errors="errors"
            :config="{ dense: true }"
            @validate="validate($event)"
          />
          <a-form-model
            v-model="data"
            :model="model"
            :errors="errors"
            :config="{ dense: true }"
            @validate="validate($event)"
          />
        </v-col>
      </v-row>
      <v-row v-if="dataTask" class="pb-3 mb-3">
        <v-col cols="4">
          <h3>Документы из задачи #{{ dataTask.id }}</h3>
        </v-col>
        <v-col cols="8">
          <a-view-files
            :model="{ name: 'files', title: 'Документы из задачи' }"
            :value="dataTask.files || []"
          />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-status
          v-if="getAccess('financeStatus', {})"
          :items="statusTaskItems"
          @click="clickStatus($event)"
          :disabled="loading || isChanged"
        />
        <a-btn-save
          @click="submit()"
          v-if="
            data.status === 1 &&
            (getAccess('financeEdit', { users: [data.createdby_id] }) || !id)
          "
          :color="isChanged ? 'green' : 'primary'"
        ></a-btn-save>
        <a-btn-delete
          v-if="id && data.status != 2 && getAccess('financeDelete', { data })"
          @click="removeDialogShow = true"
        />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
    <su-Data
      ref="suEdit"
      v-model="suEditShow"
      :api="api"
      :id="id"
      :activate="value"
      :dataEdit="data"
      @show="suEditShow = true"
      v-if="getAccess('suData')"
    />
  </div>
</template>

<script>
import {
  getForm,
  submitForm,
  removeEl,
  getAccess,
} from "../../components/mixings";

export default {
  mixins: [getForm, submitForm, removeEl, getAccess],
  props: {
    value: Boolean,
    id: Number,
    object_id: Number,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.finance,
      statuses: this.$store.getters["config/get"].models.finance.status,
      api: "/mechti/finance",
      apiStatus: "/mechti/finance",
      dataTask: null,
      removeDialogShow: false,
      suEditShow: false,
      // isChanged: false,
    };
  },
  computed: {
    model() {
      let model = JSON.parse(JSON.stringify(this.m.form2));
      model.forEach((el) => {
        if (el.name == "type") {
          el.type = "";
        }
        if (el?.sourceList) {
          el.options = this.m[el.sourceList];
        }
      });
      return model;
    },
    statusTaskItems() {
      let items = [];
      let res = [];
      if (this.statusCurrent?.["next"]) {
        let arr = this.statusCurrent["next"];
        res = this.statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));

      return items;
    },
    statusCurrent() {
      let idx = this.statuses.findIndex((el) => {
        return el.value == this.data.status;
      });
      if (idx == -1) return {};
      return JSON.parse(JSON.stringify(this.statuses[idx]));
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.isChanged = false;
        this.dataTask = null;
        this.updateData(this.id);
        this.isChanged = false;
        if (!this.id) {
          this.data.object_id = this.object_id;

          this.data.date_doc = this.data?.date_doc || new Date();
        }
      } else {
        this.$store.dispatch("state/badges", "finance_items");
      }
    },
    data: {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue.id !== null) {
          // console.log(oldValue.id, newValue.id);
          // this.isChanged = true;
        }
      },
    },
  },
  methods: {
    async afterFetchData() {
      const params = { filters: { params: JSON.stringify(this.data.params) } };
      const d = await this.$axios.get("/mechti/tasks", { params });
      if (d.data && d.data.data) {
        this.dataTask = d.data.data[0];
      }
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      this.isChanged = false;
      if (this.id) return;
      if (status) this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
    async clickStatus(status) {
      this.newStatus = null;

      let ok = false;

      ok = await this.$refs.confirmDialog.show({
        title: "Изменение статуса",
        message: [
          `Вы действительно хотите поменять статус на [${status.text}]?`,
        ],
        okButton: "Да",
        cancelButton: "Нет",
      });
      if (!ok) return;

      if (status.func) {
        this[status.func](status);
      } else {
        this.changeStatus(status);
      }
    },

    async changeStatus(status) {
      //return;
      this.loading = true;
      let data = { id: this.data.id, status: status.value };
      let response = await this.$axios.post(this.apiStatus, data);
      this.loading = false;
      status = response.data.status == "ok";
      if (response.data.data) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Статус изменен",
        });
      }
    },
  },
};
</script>